import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {useToast} from "@Hooks/toast";
import {getBase64} from "@Utils/import.utils";
import {Resource} from "@Types/Resource";
import {firestore} from "@Utils/config/firebase";

export type UploadButtonProps = {
	buttonClassName?: string;
	className?: string;
	label: string;
	file_id?: string | null;
	icon?: string;
	accept?: string[];
	iconPos?: "top" | "bottom" | "left" | "right";
	showPreview?: boolean;
	loading?: boolean;
	onChange?: (file: File) => void;
	onRemove?: (file_id?: string | null) => void;
}
export const UploadButton: React.FC<UploadButtonProps> = props => {

	const [filePreview, setFilePreview] = useState<string>();

	const ref = useRef<HTMLInputElement>(null);

	const {error} = useToast();

	const [currentFile, setCurrentFile] = useState<File>();

	const [loadedFile, setLoadedFile] = useState<Resource>();

	useEffect(() => {
		if (props.file_id) {
			firestore.collection<Resource>('resources').get(props.file_id).then(res => {
				setLoadedFile(res);
			});
	    }
		return () => {
			setFilePreview(undefined);
			setCurrentFile(undefined);
			setLoadedFile(undefined);
		};
	}, [props.file_id]);

	const onRemove = () => {
		setFilePreview(undefined);
		setCurrentFile(undefined);
		setLoadedFile(undefined);
		props.onRemove?.(props.file_id);
	}

	const onSelect = (files: FileList) => {
		const file = files[0];
		if (props.accept && !props.accept.includes(file.type)) {
			error(`Veuillez sélectionner un fichier ${props.accept.map(e => e.split('/')[1]).join(', ')}`);
			return;
		}
		setCurrentFile(file);
		if (props.showPreview) {
			getBase64(file).then((base64) => {
				props.onChange?.(file);
				setFilePreview(base64);
			})
		} else {
			props.onChange?.(file);
		}
	};

	return (
		<div className={props.className}>
			<input
				ref={ref}
				type={"file"}
				hidden
				accept={props.accept?.join(', ') || '*'}
				onChange={e => {
					e.target.files && e.target.files[0] && onSelect(e.target.files)
				}}/>
			{
				(filePreview || loadedFile) &&
                <div className="flex align-items-center">
					{
						props.showPreview ?
							<img src={filePreview || loadedFile?.resource_url} style={{width: 100, height: "auto"}}/>
							:
							<div className="he-button--primary-nfb--xs">
								<i className="pi pi-file-o"/>
								{currentFile?.name || loadedFile?.resource_name}
							</div>
					}
					<div className="flex flex-column gap-1 align-items-center justify-content-center ml-3">
						<i className="pi pi-times-circle color-red cursor-pointer" onClick={onRemove}/>
					</div>
                </div>
			}
			{
				!loadedFile && !currentFile &&
                <Button className={props.buttonClassName} onClick={() => ref.current?.click()} label={props.label}
                        icon={props.icon} iconPos={props.iconPos}/>
			}

		</div>
	)
}
