import {Theme} from '@Types/Theme';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {BackofficeContext} from "@Context/BackofficeContext";
import {Module} from "@Types/Module";
import {Dropdown} from "primereact/dropdown";
import {useToast} from "@Hooks/toast";
import {doc, updateDoc} from "firebase/firestore";
import {createBatch, firestore} from "@Utils/config/firebase";
import {uniq} from "lodash";
import {iconForActivity} from "@Utils/activity.utils";
import {classNames} from "primereact/utils";
import styled from "styled-components";
import {getTextColor} from "@Utils/misc.utils";

interface Props {
    themes: Theme[],
    formation_id: string;
}
export const ThemeAssociation: React.FC<Props> = (props) => {

    /* STATES */

    const {formations, modules} = useContext(BackofficeContext);

    const formation = useMemo(() => {
        return formations.find(f => f.formation_id === props.formation_id);
    }, [formations, props.formation_id])

    const {error, success} = useToast();

    const onAssociationChange = async (theme_id: string, activity_id: string, op: "add" | "remove") => {
        const theme = props.themes.find(t => t.theme_id === theme_id);
        if (!theme) return;

        try {
            const currentThemeRef = doc(firestore.db, "formations", props.formation_id, "themes", theme_id);
            if (op === "add") {
                await updateDoc(currentThemeRef, "activity_ids", uniq([...theme.activity_ids, activity_id]));
            }
            else {
                await updateDoc(currentThemeRef, "activity_ids", theme.activity_ids.filter(id => id !== activity_id));
            }
            const batch = createBatch();
            for (const theme of props.themes) {
                if (theme.theme_id === theme_id) continue;
                if (theme.activity_ids.includes(activity_id)) {
                    const ref = doc(firestore.db, "formations", props.formation_id, "themes", theme.theme_id);
                    batch.update(ref, {
                        activity_ids: theme.activity_ids.filter(id => id !== activity_id)
                    });
                }
            }
            await batch.commit();
            success("Modification enregistrée");
        }
         catch (e) {
            error("Erreur lors de l'association de l'activité au thème");
         }
    }

    const optionTemplate = useCallback((option: {value: string, label: string}) => {
        const theme = props.themes.find(t => t.theme_id === option.value);
        if (!theme) return " - ";
        return <ThemeTag background={theme.color}>{option.label}</ThemeTag>
    }, [props.themes])


    return (
        <div className="flex flex-column gap-3 w-6">
            {formation?.units.map((u, i) => {

                const unitModules = u.modules_ids.map(mid => modules.find(m => m.module_id === mid)).filter(Boolean) as Module[];

                return (
                    <div key={u.unit_id} className="w-full">
                        <div className="he-header--h3 gray-800 mb-2">Unité {i+1}</div>
                        <div className="flex flex-column gap-2 pl-4 border-left-1 border-gray-500 w-full">
                            {unitModules.map(m => {
                                return (
                                    <div key={m.module_id} className="w-full">
                                        <div className="he-header--h4 gray-800 mb-2">Module : {m.title}</div>
                                        <div className="pl-4 flex flex-column gap-1 border-left-1 border-gray-500 w-full">
                                            {m.activities.map(a => {
                                                if (!a.activity_id) return null;
                                                const activityTheme = props.themes.find(t => t.activity_ids.includes(a.activity_id!));
                                                return (
                                                    <div key={a.activity_id} className="flex gap-2 align-items-center w-full justify-content-between">
                                                        <div className="he-paragraph--regular gray-600"><i className={classNames(iconForActivity(a.type), "mr-1")}/> {a.title}</div>
                                                        <div className='flex gap-2 align-items-center'>
                                                            <CustomDropdown
                                                                options={props.themes.map(t => ({label: t.label, value: t.theme_id}))}
                                                                value={activityTheme?.theme_id}
                                                                placeholder="Sélectionner un thème"
                                                                itemTemplate={optionTemplate}
                                                                valueTemplate={option => option ? optionTemplate(option) : <span className="he-paragraph--small gray-600">Aucun thème associé</span>}
                                                                onChange={(e) => {
                                                                    onAssociationChange(e.value, a.activity_id!, "add");
                                                                }}
                                                            />
                                                            {activityTheme?.theme_id ? <i className='pi pi-times-circle cursor-pointer' onClick={() => {
                                                                onAssociationChange(activityTheme?.theme_id, a.activity_id!, "remove");
                                                            }}/> : <span style={{width: 16}}/>}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    );

}

const ThemeTag = styled.div<{
    background?: string;
    color?: string;
}>`
    padding: 6px 12px;
    border-radius: 16px;
    background: ${props => props.background || "#c8c8c8"};
    color: ${props => props.color || getTextColor(props.background || "#c8c8c8")};
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    font-family: 'roboto';
`

const CustomDropdown = styled(Dropdown)`
    border: 1px solid var(--gray-300);
    
    .p-dropdown-label {
        padding: 0 0 0 8px;
        display: flex;
        align-items: center;
    }
    
`
