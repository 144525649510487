import React, {useMemo} from "react";
import {classNames} from "primereact/utils";
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import {fr} from "suneditor/src/lang";
import plugins from "suneditor/src/plugins";
import {useFormik} from "formik";

export type CustomTextEditorProps = {
	field?: string;
	formik?: ReturnType<typeof useFormik<any>>;
	value?: string;
	onChange?: (value: string) => void;
	label?: string;
	placeholder?: string;
	className?: string | (boolean | string | undefined)[];
	disabled?: boolean;
	height?: string;
	debounce?: number;
	buttonList?: string[][];
	zIndex?: number;
}

export const CustomTextEditor: React.FC<CustomTextEditorProps> = props => {

	const defaultProps = useMemo<CustomTextEditorProps>(() => ({
		className: [],
		buttonList: [
			['undo', 'redo'],
			['outdent', 'indent'],
			['font', 'fontSize', 'formatBlock'],
			['paragraphStyle', 'blockquote'],
			['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
			['align', 'horizontalRule', 'list', 'lineHeight'],
			['fullScreen', 'codeView'],
			['removeFormat'],
		],
		...props,
	}), []);

	return (
		<div
			className={classNames("flex flex-column", ...(typeof defaultProps.className === "string" ? [defaultProps.className] : (defaultProps.className ?? [])))}>
			{defaultProps.label && <label className="he-paragraph--regular gray-500 mb-2"
                                          htmlFor={props.field}>{defaultProps.label}</label>}
			<div
				className={classNames("w-full")}
				style={{zIndex: defaultProps.zIndex ?? 0}}
			>
				<SunEditor
					placeholder={defaultProps.placeholder}
					defaultValue={((props.formik && props.field) ? props.formik.values[props.field] : props.value )|| ""}
					width="100%"
					setDefaultStyle="font-family: Roboto; font-size: 12px;"
					setOptions={{
						height: props.height ?? "auto",
						lang: fr,
						plugins,
						buttonList: defaultProps.buttonList,
						historyStackDelayTime: defaultProps.debounce,
					}}
					onChange={(str) => {
						if (str) {
							if (props.formik && props.field)
								props.formik.setFieldValue(props.field, str, false)
							if (props.onChange)
								props.onChange(str);
						}
					}}
				/>
			</div>
		</div>
	)
}
