import { Module } from "@Types/Module";
import { Session, SessionExtended } from "@Types/Session";
import { AuditRecord, UserProgress, UserProgressItem } from "@Types/User";
import _ from "lodash";

export const isSessionStarted = (session: Session, progress?: UserProgress) => {
	const sessionProgress = progress?.[session.session_id];
	return !sessionProgress;
}

export const getLastActivity = (progress: UserProgress, session: SessionExtended<false>) => {

	const sessionProgress = progress[session.session_id];

	if (!sessionProgress) return undefined;

	const lastModuleId = _.last(_.intersection(session.formation.modules.map(e => e.module_id), Object.keys(sessionProgress)));

	if (!lastModuleId) return undefined;

	const lastModule = sessionProgress[lastModuleId];

	if (!lastModule) return undefined;

	const lastActivity = _.last(Object.entries(lastModule));

	if (!lastActivity) return undefined;

	//      module_id      activity_id      activity_history
	return {module_id: lastModuleId, activity_id: lastActivity[0], activity: lastActivity[1]}

}

export const getActivityProgression = (
    modules: Module[],
    progress: UserProgressItem[],
    records: AuditRecord[],
) => {

	const progressionTotale = modules.reduce<[number, number][]>((a,c) => {
		console.log("Module: ", c)
		if (c.type === "audit") {
			const moduleRecords = records.filter((r) => r.module_id === c.module_id);
			console.log(moduleRecords);
			const totalMaxRecords = Math.min(Math.max(c.min_records, moduleRecords.length), c.max_records);
			const total = c.activities.length * totalMaxRecords;
			const completedActivities = moduleRecords.reduce((acc, record) => {
				return acc + record.answers.length;
			}, 0);

			a.push([completedActivities, total]);
		}
		else {
			const totalActivity = c.activities.length;
			const moduleProgress = progress.filter(p => p.module_id === c.module_id && p.activity.done);
			a.push([moduleProgress.length, totalActivity]);
		}
		return a;
	}, []);

	return 100 * progressionTotale.reduce((a, [count, total]) =>  {
		return a + count / total;
	}, 0) / modules.length;

};