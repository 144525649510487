import { Async } from '@Components/Async';
import { CustomActionDropdown } from '@Components/CustomActionDropdown';
import { CustomDialog } from '@Components/CustomDialog';
import { CustomTable } from '@Components/CustomTable';
import { BackofficeContext } from '@Context/BackofficeContext';
import { SessionContext } from '@Context/SessionContext';
import { useApi } from '@Hooks/api';
import { usePromise } from '@Hooks/promise';
import { ExtendedParticipant } from '@Pages/pages/Sessions/pages/ParticipantList';
import { UnitProgress } from '@Pages/pages/Sessions/pages/ParticipantList/components/UnitProgress';
import { Session, SessionTimeReport } from '@Types/Session';
import { UnitConfig, UnitMeta } from '@Types/Unit';
import { createBatch, firestore } from '@Utils/config/firebase';
import { formatDate } from '@Utils/date.utils';
import { downloadFromURL, downloadUint8 } from '@Utils/download.utils';
import { getActivityProgression } from '@Utils/progress.utils';
import { doc } from 'firebase/firestore';
import { produce } from 'immer';
import { chunk } from 'lodash';
import { Duration } from 'luxon';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { TieredMenu } from 'primereact/tieredmenu';
import React, { useContext, useMemo, useRef, useState } from 'react';

type UnitMetaTable = {
    label: string;
    progress: number;
    elapsed_time: Promise<number>;
    config?: UnitConfig;
} & UnitMeta;

export type ParticipantUnitTableProps = {
    participant: ExtendedParticipant;
    session: Session;
    timeReport?: SessionTimeReport;
};
export const ParticipantUnitTable: React.FC<ParticipantUnitTableProps> = (props) => {
    const { formations, modules } = useContext(BackofficeContext);
    const { usersRecords, usersProgress, reloadProgress } = useContext(SessionContext);

    const api = useApi();

    const ref = useRef<TieredMenu>(null);

    const [selected, setSelected] = useState<string | null>(null);

    const units = useMemo<UnitMetaTable[]>(() => {

        const getUnitModuleIds = (unit_id: string): string[] => {
            const formation = formations.find((f) => f.formation_id === props.session.formation_id);
            if (!formation) return [];

            const unit = formation.units.find((u) => u.unit_id === unit_id);

            if (!unit) return [];

            return unit.modules_ids;
        };

        const getTimeForUnit = async (unit_id: string) => {
            const modules = getUnitModuleIds(unit_id);

            const sessionModuleTimes = props.timeReport?.modules;

            if (modules.length === 0 || !sessionModuleTimes) return 0;

            const unitTimes = modules.map((m_id) => sessionModuleTimes.find((m) => m.module_id === m_id));

            return unitTimes.reduce((acc, c) => (c?.total || 0) + acc, 0);
        };

        const getAdvancementForUnit = (unit_id: string) => {

            const moduleIds = getUnitModuleIds(unit_id);
            console.log(usersProgress[props.participant.participant_id])

            const hasProgress = usersProgress[props.participant.participant_id]?.length;
            const hasRecords = usersRecords[props.participant.participant_id]?.filter(r =>moduleIds.includes(r.module_id) && r.session_id === props.session.session_id)?.length;

            if (!hasRecords && !hasProgress) return -1;
            
            const progress = usersProgress[props.participant.participant_id]?.filter(
                (p) => p.session_id === props.session.session_id
            ) ?? [];

            const records = usersRecords[props.participant.participant_id]?.filter(
                (r) => r.session_id === props.session.session_id
            ) ?? [];


            const formation = formations.find((f) => f.formation_id === props.session.formation_id);
            
            if (!formation) return -1;

            const formationModules = moduleIds
                .map((m_id) => modules.find((m) => m.module_id === m_id))
                .filter((m) => m !== undefined);


            return getActivityProgression(formationModules, progress, records);
        };

        return props.participant.unitsMeta
            ? props.participant.unitsMeta.map((u, i) => ({
                  ...u,
                  label: `Unit #${i + 1}`,
                  progress: getAdvancementForUnit(u.unit_id),
                  elapsed_time: getTimeForUnit(u.unit_id),
                  config: props.session.unitsConfig.find((c) => c.unit_id === u.unit_id),
              }))
            : [];
    }, [
        props.participant.unitsMeta,
        props.participant.participant_id,
        props.session.formation_id,
        props.session.session_id,
        props.session.unitsConfig,
        props.timeReport?.modules,
        formations,
        usersProgress,
        usersRecords,
        modules,
    ]);

    const [onUpdateAccess, loading] = usePromise(
        async function (participant: ExtendedParticipant, unit_id: string, status: boolean) {
            await api.participant_call_update({
                participant: {
                    ...props.participant,
                    unitsMeta: produce(participant.unitsMeta, (draft) => {
                        return draft
                            ? draft.map((meta) => {
                                  if (meta.unit_id === unit_id) {
                                      return {
                                          ...meta,
                                          access: status,
                                      };
                                  }
                                  return meta;
                              })
                            : [];
                    }),
                },
                session_id: props.session.session_id,
            });

            if (status) {
                const progress = usersProgress[props.participant.participant_id]?.filter(
                    (p) => p.session_id === props.session.session_id
                );

                if (!progress) return;

                const unitModules =
                    formations
                        .find((f) => f.formation_id === props.session.formation_id)
                        ?.units.find((u) => u.unit_id === unit_id)?.modules_ids || [];

                const unitProgress = progress.filter((p) => unitModules.includes(p.module_id));

                const chunked = chunk(unitProgress, 500);

                for (const chunk of chunked) {
                    const batch = createBatch();
                    for (const p of chunk) {
                        batch.update(
                            doc(firestore.db, 'users', props.participant.participant_id, 'progress', p.progress_id),
                            'activity.done',
                            true
                        );
                    }
                    await batch.commit();
                }
                reloadProgress();
            }
        },
        {
            pending: "Mise à jour de l'accès en cours...",
            success: 'Mise à jour terminée !',
        }
    );

    const [selection, setSelection] = useState<UnitMetaTable | undefined>();

    const [onDownloadAttestation] = usePromise(
        async (unit_id: string, session_id: string, participant_id: string) => {
            const res = await api.pdf_call_downloadAttestation({
                unit_id,
                session_id,
                participant_id,
            });

            if (res.result !== 'ok') throw new Error(res.result);

            const formation = formations.find((f) => f.formation_id === props.session.formation_id);
            if (formation) {
                const index = formation.units.findIndex((u) => u.unit_id === unit_id);
                downloadUint8(
                    res.pdf,
                    `Attestation sur l'honneur formation - ${formation.andpc} - Session ${
                        props.session.session_custom_id
                    } - ${props.participant.lastname} ${props.participant.firstname} - Unité ${index + 1}.pdf`,
                    'application/pdf'
                );
            }
        },
        {
            pending: "récupération de l'attestation en cours...",
            success: 'Le téléchargement va démarrer !',
        }
    );

    const [downloadUsersLog] = usePromise(
        async (user_ids: string[], unit_ids: string[]) => {
            const res = await api.session_call_getUserLogs({
                session_id: props.session.session_id,
                user_ids,
                unit_ids,
            });

            if (res.result !== 'ok') throw new Error(res.result);
            await downloadFromURL(res.url);
        },
        {
            pending: 'Génération des logs en cours, cette opération peut prendre du temps...',
            success: 'Logs créer le téléchargement va démarrer',
        }
    );

    const [deleteSignatureDialog, setDeleteSignatureDialog] = useState<boolean>(false);

    const [onDeleteSignature] = usePromise(
        async (unit_id: string) => {
            return api.participant_call_cancelSignature({
                unit_id,
                session_id: props.session.session_id,
                participant_id: props.participant.participant_id,
            });
        },
        {
            pending: 'Suppression en cours...',
            success: 'Signature supprimée',
        }
    );

    const options = useMemo(
        () => [
            {
                label: 'Télécharger les logs',
                icon: 'pi pi-download',
                action: () => selection && downloadUsersLog([props.participant.participant_id], [selection.unit_id]),
            },
            {
                label: "Télécharger l'attestation sur l'honneur",
                icon: 'pi pi-download',
                action: () =>
                    selection &&
                    onDownloadAttestation(
                        selection.unit_id,
                        props.session.session_id,
                        props.participant.participant_id
                    ),
            },
            ...(selection?.signature_id
                ? [
                      {
                          label: 'Supprimer la signature',
                          icon: 'pi pi-trash',
                          action: () => selection && setDeleteSignatureDialog(true),
                      },
                  ]
                : []),
        ],
        [selection]
    );

    const onActionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, unit: UnitMetaTable) => {
        setSelection(unit);
        ref.current?.toggle(e);
    };
    const UnitDropdown = (unit: UnitMetaTable) => {
        return (
            ref.current && (
                <div
                    className="he-paragraph--small gray-900 flex align-items-center cursor-pointer"
                    onClick={(e) => onActionClick(e, unit)}
                >
                    <div className="mr-1">Actions</div>
                    <i className="pi pi-angle-down gray-400" style={{ fontSize: 9 }} />
                </div>
            )
        );
    };

    return (
        <div className="ParticipantUnitTable p-3">
            <div className="he-header--h3 mb-3">
                Détails {props.participant.firstname} {props.participant.lastname}
            </div>
            {Boolean(selected) && (
                <CustomDialog onHide={() => setSelected(null)} width={900} closable>
                    <div className="fixed bg-white" style={{ height: 20, width: 830 }}>
                        <i className={'pi pi-times cursor-pointer'} onClick={() => setSelected(null)} />
                    </div>
                    {selected && (
                        <UnitProgress
                            unit_id={selected}
                            session={props.session}
                            timeReport={props.timeReport}
                            participant_id={props.participant.participant_id}
                        />
                    )}
                </CustomDialog>
            )}

            {deleteSignatureDialog && selection && deleteSignatureDialog && (
                <CustomDialog onHide={() => setDeleteSignatureDialog(false)} width={500} closable>
                    <div className="he-header--h3 mb-3">Supprimer la signature</div>
                    <div className="he-paragraph--medium">
                        Êtes-vous sûr de vouloir supprimer la signature de {props.participant.firstname}{' '}
                        {props.participant.lastname} ?
                    </div>
                    <div className="flex justify-content-end mt-5">
                        <Button
                            className="he-button--secondary-nfb--md mr-4"
                            onClick={() => setDeleteSignatureDialog(false)}
                        >
                            Annuler
                        </Button>
                        <Button
                            className="he-button--primary--md"
                            onClick={() =>
                                onDeleteSignature(selection.unit_id).finally(() => {
                                    setDeleteSignatureDialog(false);
                                    setSelection(undefined);
                                })
                            }
                        >
                            Confirmer
                            <i className="pi pi-trash ml-1" />
                        </Button>
                    </div>
                </CustomDialog>
            )}
            <CustomActionDropdown options={options} ref={ref} />
            <CustomTable
                values={units}
                dataKey="unit_id"
                paginator={false}
                columns={[
                    {
                        field: 'label',
                        header: 'Label',
                        size: 80,
                        body: (meta) => (
                            <div
                                className="he-paragraph--medium cursor-pointer primary-600"
                                onClick={() => setSelected(meta.unit_id)}
                            >
                                {meta.label}
                            </div>
                        ),
                    },
                    {
                        field: 'config.start_date',
                        header: 'Date début',
                        size: 120,
                        body: (meta) => (
                            <div>{meta.config?.start_date ? formatDate(meta.config.start_date) : ' - '}</div>
                        ),
                    },
                    {
                        field: 'config.end_date',
                        header: 'Date fin',
                        size: 120,
                        body: (meta) => <div>{meta.config?.end_date ? formatDate(meta.config.end_date) : ' - '}</div>,
                    },
                    {
                        field: 'progress',
                        header: 'Avancement',
                        size: 100,
                        body: (meta) => (
                            <div>
                                {meta.progress === -1
                                    ? ' - '
                                    : new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(
                                          meta.progress || 0
                                      )}{' '}
                                %
                            </div>
                        ),
                    },
                    {
                        field: 'elapsed_time',
                        header: 'Temps passé',
                        size: 150,
                        body: (meta) => (
                            <Async
                                default={0}
                                promise={meta.elapsed_time}
                                render={(value) => (
                                    <div>{value ? Duration.fromMillis(value).toFormat('hh:mm:ss') : ' - '}</div>
                                )}
                            />
                        ),
                    },
                    {
                        field: 'signature_id',
                        header: 'Signature',
                        size: 100,
                        body: (meta) =>
                            meta.signature_id ? (
                                <i className="pi pi-check color-green" />
                            ) : (
                                <i className="pi pi-times color-red" />
                            ),
                    },
                    {
                        field: 'access',
                        size: 100,
                        header: 'Valider unité',
                        body: (meta) => (
                            <div className="flex align-items-center">
                                <InputSwitch
                                    checked={meta.access}
                                    disabled={loading}
                                    onChange={(e) => onUpdateAccess(props.participant, meta.unit_id, e.value)}
                                />
                                {loading && <i className="pi pi-spin pi-spinner ml-2" style={{ fontSize: '1.2rem' }} />}
                            </div>
                        ),
                    },
                    {
                        frozen: true,
                        size: 100,
                        alignFrozen: 'right',
                        body: UnitDropdown,
                    },
                ]}
            />
        </div>
    );
};
