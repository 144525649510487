import React, { useEffect, useState } from 'react';
import type { User, UserNote, UserNoteGroup as UserNoteGroupType } from '@Types/User';
import { useToast } from '@Hooks/toast';
import { useUser } from '@Hooks/firebase';
import {collection, onSnapshot} from 'firebase/firestore';
import { firestore } from '@Utils/config/firebase';
import _ from 'lodash';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import {produce} from 'immer';
import { Line } from '@Components/UI/Line';
import { DateTime } from 'luxon';
import { v4 } from 'uuid';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { CustomDialog } from '@Components/CustomDialog';
import { EditComment } from '@Pages/pages/components/UserInformation/UserNotes/EditComment';
import { ReadOnlyEditor } from '@Components/ReadOnlyEditor';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';

export type Props = {
    user: User;
    group: UserNoteGroupType,
    titles: string[]
}
export const UserNoteGroup: React.FC<Props> = props => {

    const [notes, setNotes] = useState<UserNote[]>([]);
    const [initialized, setInitialized] = useState<boolean>(false);


    const [loading, setLoading] = useState<boolean>(false);

    const { success } = useToast();

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(props.group.label);
    const [loadingNewComment, setLoadingNewComment] = useState<boolean>(false);

    const {meta} = useUser(true);
    useEffect(() => {
        setLoading(true);
        console.log({group: props.group, user: props.user})
        return onSnapshot(
            collection(firestore.db, 'users', props.user.user_id, 'notes', props.group.group_id, "comments"),
            snapshot => {
                const fetchedNotes = snapshot.docs.map(doc => doc.data() as UserNote);
                console.log(fetchedNotes);
                setNotes(_.sortBy(fetchedNotes, "created_at").reverse());
                setActiveIndexes(fetchedNotes.map((_,i) => i));
                if (!initialized) {
                    setInitialized(true);
                }
                setLoading(false);
            },
        );
    }, [props.user, props.group, initialized]);

    const onDeleteGroup = async () => {
        if (props.group.group_id === "default") return;
        const dialog = confirmDialog({
            message: `Êtes-vous sûr de vouloir supprimer la note ${title} ?`,
            header: 'Supprimer la note',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Oui',
            rejectLabel: 'Non',
            accept: async () => {
                await firestore.collection(`users/${props.user.user_id}/notes`).delete(props.group.group_id);
                dialog.hide();
                success("Note supprimée !")
            },
            reject: () => {}
        })
    }

    const onSaveTitle = async () => {
        await firestore.collection(`users/${props.user.user_id}/notes`).set(props.group.group_id, produce(props.group, draft => {
           draft.label = title;
        }));
        setIsEditing(false);
    }

    const [filteredSuggestionList, setFilteredSuggestionList] = useState<string[]>([]);

    const completeMethod = (e: { query: string; }) => {
        let results = props.titles.filter((label) => {
            const regex = new RegExp(`${e.query}`, 'gi');
            return regex.test(label)
        });
        setFilteredSuggestionList(results);
    }

    const onAddComment = async () => {
        const newNote: UserNote = {
            note_id: v4(),
            label: `Commentaire ${notes.length + 1}`,
            created_at: DateTime.now().toISO() ?? Date().toString(),
            note: ""
        };

        setLoadingNewComment(true);
        await firestore.collection(`users/${props.user.user_id}/notes/${props.group.group_id}/comments`).set(newNote.note_id, newNote);
        setLoadingNewComment(false);
    }

    const onSaveComment = async (note: UserNote) => {
        await firestore.collection(`users/${props.user.user_id}/notes/${props.group.group_id}/comments`).set(note.note_id, note);
        success("Commentaire modifié !");
        await refreshAccordions();
        setEditingComment(null);
    }

    const onDeleteComment = async (note: UserNote) => {
        const dialog = confirmDialog({
            message: `Êtes-vous sûr de vouloir supprimer le commentaire ${note.label} ?`,
            header: 'Supprimer le commentaire',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Oui',
            rejectLabel: 'Non',
            accept: async () => {
                await firestore.collection(`users/${props.user.user_id}/notes/${props.group.group_id}/comments`).delete(note.note_id);
                dialog.hide();
                success("Commentaire supprimé !")
            },
            reject: () => {}
        })
    }

    const [activeIndexes, setActiveIndexes] = useState<number[]>([]);

    const refreshAccordions = () => {
        setActiveIndexes([]);
        return new Promise<void>(resolve => {
            setTimeout(() => {
                setActiveIndexes(notes.map((_,i) => i));
                resolve();
            }, 500);
        })
    }

    const [editingComment, setEditingComment] = useState<UserNote | null>(null);

    return (
        loading ?
            <div className='w-full flex align-items-center justify-content-center p-5'>
                <i className='pi pi-spin pi-spinner' />
            </div>
            :
            <div className='w-full flex flex-column gap-3'>
                <ConfirmDialog />
                <div className="flex justify-content-start align-items-center gap-3">
                    {
                        isEditing ?
                            <AutoComplete
                                placeholder="Titre de la note"
                                dropdown
                                suggestions={filteredSuggestionList}
                                value={title}
                                completeMethod={completeMethod}
                                onChange={e => setTitle(e.value)}
                            />
                            :
                            <span className={"he-header--h2 gray-500"}>{title}</span>
                    }
                    <i className={classNames(isEditing ? "pi pi-save" : "pi pi-pencil", "primary-400", "cursor-pointer")} onClick={() => {
                        if (!isEditing) {
                            setIsEditing(true);
                        } else {
                            onSaveTitle();
                        }
                    }}/>
                    { (meta.role === "admin" || meta.role === "author") && <i className={"cursor-pointer ml-auto color-red pi pi-trash"} onClick={onDeleteGroup}/>}
                </div>
                <Line height={1} className={"color-gray-400 gray-400 bg-gray-300"} />
                <div className='he-header--h3 gray-500'>Commentaires {
                    loadingNewComment ? <i className="pi pi-spin pi-spinner ml-2" /> :
                    <i className="cursor-pointer pi pi-plus-circle ml-2 primary-400" onClick={onAddComment }/>
                }</div>
                <Accordion multiple activeIndex={activeIndexes} className={"w-full overflow-auto"} style={{maxHeight: 280}} onTabChange={e => setActiveIndexes(e.index as any)}>
                    {notes.map(n => {
                        return <AccordionTab
                            key={n.note_id}
                            headerTemplate={
                            <div className='flex gap-2 align-items-center w-full'>
                                {n.label}
                                <Button
                                    className="he-button--secondary-variant--xs ml-auto m-0"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setEditingComment(n);
                                    }}
                                >
                                    <i className={"pi pi-pencil"}/>
                                    Modifier
                                </Button>
                                <Button
                                    className="he-button--danger--xs"
                                    style={{width: 36}}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onDeleteComment(n);
                                    }}
                                >
                                    <i className={"pi pi-trash mx-1"}/>
                                </Button>
                            </div>}
                        >
                            <ReadOnlyEditor content={n.note || "Aucun commentaire"} />
                        </AccordionTab>
                    })}
                </Accordion>
                {
                    editingComment && <CustomDialog onHide={() => setEditingComment(null)}>
                        <EditComment
                            note={editingComment}
                            group={props.group}
                            user={props.user}
                            onSave={onSaveComment}
                            onCancel={() => setEditingComment(null)}
                        />
                    </CustomDialog>
                }
            </div>
    );
};


