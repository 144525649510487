import React from "react";
import {
    Activity as ActivityModel,
    Activity,
    QuizzActivity as QuizzActivityModel,
    TCSActivity as TCSActivityModel,
    VideoActivity as VideoActivityModel,
    FreeTextActivity as FreeTextActivityModel,
} from '@Types/Activity';
import { VideoActivity } from "./ActivityPreview/VideoActivity";
import {CourseActivity} from "@Pages/pages/Formations/PreviewFormation/ActivityPreview/CourseActivity";
import {QuizzActivity} from "@Pages/pages/Formations/PreviewFormation/ActivityPreview/QuizzActivity";
import {TCSActivity} from "@Pages/pages/Formations/PreviewFormation/ActivityPreview/TCSActivity";
import { FreeTextActivity } from "./ActivityPreview/FreeTextActivity";

export type ActivityPreviewProps = {
    activity: Activity;
}
export const ActivityPreview: React.FC<ActivityPreviewProps> = (props) => {

    switch (props.activity.type) {
        case 'course':
            return <CourseActivity activity={props.activity} key={props.activity.activity_id}/>
        case 'video':
            return <VideoActivity activity={props.activity as ActivityModel & VideoActivityModel} key={props.activity.activity_id} />
        case 'mcq':
        case 'ucq':
            return <QuizzActivity activity={props.activity as ActivityModel & QuizzActivityModel}
                                  key={props.activity.activity_id} />
        case 'tcs':
            return <TCSActivity activity={props.activity as ActivityModel & TCSActivityModel}
                                key={props.activity.activity_id} />
        case 'freetext':
            return <FreeTextActivity activity={props.activity as ActivityModel & FreeTextActivityModel}
                                     key={props.activity.activity_id} />
        default:
            return <div>
                {props.activity.type}
            </div>
    }
}
