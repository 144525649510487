import { Line } from "@Components/UI/Line";
import { BackofficeContext } from "@Context/BackofficeContext";
import { SessionContext } from "@Context/SessionContext";
import { useToast } from "@Hooks/toast";
import { Session, SessionTimeReport } from "@Types/Session";
import { createBatch, firestore } from "@Utils/config/firebase";
import { durationFromEstimated } from "@Utils/date.utils";
import { doc, updateDoc } from "firebase/firestore";
import _ from "lodash";
import { DateTime, Duration } from "luxon";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Tag } from "primereact/tag";
import React, { useContext, useMemo } from "react";

export type UnitProgressProps = {
	session: Session,
	timeReport?: SessionTimeReport,
	unit_id: string,
	participant_id: string,
}
export const UnitProgress: React.FC<UnitProgressProps> = (props) => {

	const {formations, modules} = useContext(BackofficeContext);
	const {reloadProgress, usersProgress, usersRecords} = useContext(SessionContext);
	const {success, error} = useToast();

	const formation = useMemo(() => {
		return formations.find(f => f.formation_id === props.session.formation_id);
	}, [formations, props.session.formation_id]);

	const unitModules = useMemo(() => {
		if (!formation) return [];
		return formation.units.find(u => u.unit_id === props.unit_id)?.modules_ids.map(m => modules.find(mi => m === mi.module_id)) || [];
	}, [formation, props.unit_id, modules])


	const copyToClipboard = async (content: string) => {
		await navigator.clipboard.writeText(content);
	}

	const onUpdateActivity = async (progress_id: string, done: boolean) => {
		try {
			await updateDoc(doc(firestore.db, "users", props.participant_id, "progress", progress_id), "activity.done", done)
			reloadProgress();
			success("Activité mise à jour");
		} catch(e) {
			console.error(e);
			error("Erreur lors de la mise à jour de l'activité")
		}
	}

	return (
        <div className="UnitProgress pt-3" style={{height: 700}}>
			<div className="he-header--h1 mt-3 flex gap-2 align-items-center">
				Unité {(formation?.units.findIndex(u => u.unit_id === props.unit_id) ?? -1) + 1}
				<div style={{minWidth: 50}} className="ml-3 he-paragraph--small gray-400 cursor-pointer" onClick={() => copyToClipboard(props.unit_id)}>
					ID <i className={"pi pi-copy ml-2"}/>
				</div>
			</div>
	        {
				unitModules.map((m,i) => {
					const mod = props.timeReport?.modules.find(mInner => m?.module_id === mInner.module_id);
					const totalModule = mod?.total;
					console.log(mod, totalModule);
					return (
                        m && (
                            <div key={m.module_id}>
                                <div className="w-full he-header--h2 gray-600 mt-3 flex align-items-center">
                                    <div>
                                        Module - {m.title} -{' '}
                                        {totalModule
                                            ? Duration.fromMillis(totalModule).toFormat('hh:mm:ss')
                                            : '--:--:--'}{' '}
                                        - Temps minimum :{' '}
                                        {m.minimal_duration && m.minimal_duration_unit
                                            ? durationFromEstimated(
                                                  m.minimal_duration,
                                                  m.minimal_duration_unit
                                              )?.toFormat('hh:mm:ss')
                                            : '--:--:--'}
                                    </div>
                                    <div
                                        style={{ minWidth: 50 }}
                                        className="ml-3 he-paragraph--small gray-400 cursor-pointer"
                                        onClick={() => copyToClipboard(m.module_id)}
                                    >
                                        ID <i className={'pi pi-copy ml-2'} />
                                    </div>
                                </div>
                                {m.type === 'audit' && (
                                    <div className="w-full">
                                        <Button
                                            className="he-button--secondary--xs mt-2"
                                            onClick={() => {
                                                const submittedRecords = _.sortBy(
                                                    usersRecords[props.participant_id]?.filter(
                                                        (r) => r.module_id === m.module_id && r.submitted
                                                    ) ?? [],
                                                    (r) => r.submitted_at
                                                );

                                                const max = submittedRecords[submittedRecords.length - 1];
                                                if (max) {
                                                    const updatedRecords = usersRecords[props.participant_id]?.map(
                                                        (r) => {
                                                            if (r.module_id === m.module_id) {
                                                                return {
                                                                    ...r,
                                                                    submitted: true,
                                                                    submitted_at: max.submitted_at,
                                                                };
                                                            }
                                                            return r;
                                                        }
                                                    );

                                                    const batch = createBatch();

                                                    updatedRecords?.forEach((r) => {
                                                        batch.update(
                                                            doc(
                                                                firestore.db,
                                                                'users',
                                                                props.participant_id,
                                                                'records',
                                                                r.record_id
                                                            ),
                                                            r
                                                        );
                                                    });

                                                    batch
                                                        .commit()
                                                        .then(() => {
                                                            reloadProgress();
                                                            success('Dossiers soumis');
                                                        })
                                                        .catch((e) => {
                                                            console.error(e);
                                                            error('Erreur lors de la soumission des dossiers');
                                                        });
                                                }
                                            }}
                                        >
                                            Soumettre l'ensemble des dossiers
                                            <i className="pi pi-check-circle ml-2" />
                                        </Button>
                                    </div>
                                )}
                                <Line height={1} className={'bg-gray-300 my-3'} />
                                {m.type !== 'audit' &&
                                    m.activities.map((a, ai) => {
                                        const total = mod?.activities.find(
                                            (aInner) => aInner.activity_id === a.activity_id
                                        )?.total;
                                        const history = usersProgress[props.participant_id]?.find(
                                            (p) => p.activity_id === a.activity_id
                                        );
                                        return (
                                            a.activity_id && (
                                                <div key={a.activity_id} className="mt-3">
                                                    <div className="he-paragraph--regular gray-600">
                                                        <div className="flex align-items-center gap-3 mb-2">
                                                            <span className="he-paragraph--regular--bold">
                                                                {a.title}
                                                            </span>
                                                            <div
                                                                className="he-paragraph--small secondary-75 cursor-pointer"
                                                                onClick={() => {
                                                                    if (a.activity_id) {
                                                                        copyToClipboard(a.activity_id);
                                                                        success("ID de l'activité copié");
                                                                    }
                                                                }}
                                                            >
                                                                ID <i className={'pi pi-copy'} />
                                                            </div>
                                                        </div>
                                                        <div className="he-paragraph--small mt-1">
                                                            {history ? 'Réalisé en ' : ' - '}
                                                            {total
                                                                ? Duration.fromMillis(total).toFormat('hh:mm:ss')
                                                                : ''}
                                                        </div>
                                                    </div>
                                                    {history && (
                                                        <div>
                                                            <Checkbox
                                                                checked={history.activity.done}
                                                                id={history.progress_id}
                                                                name={history.progress_id}
                                                                onChange={(e) => {
                                                                    onUpdateActivity(
                                                                        history.progress_id,
                                                                        Boolean(e.target.checked)
                                                                    );
                                                                }}
                                                                className={'mr-2 mt-1'}
                                                            />
                                                            <label
                                                                className="he-paragraph--small"
                                                                htmlFor={history.progress_id}
                                                            >
                                                                Activité {!history.activity.done && 'non'} complétée{' '}
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        );
                                    })}
                                {m.type === 'audit' &&
                                    _.sortBy(
                                        usersRecords[props.participant_id]?.filter(
                                            (r) => r.module_id === m.module_id
                                        ) ?? [],
                                        (r) => r.created_at
                                    ).map((r) => {
                                        return (
                                            <div key={r.record_id} className="mt-3">
                                                <div className="flex gap-1 align-items-center">
                                                    <span className="he-paragraph--regular--bold">{r.meta.name}</span>
                                                    <div
                                                        className="he-paragraph--small secondary-75 cursor-pointer"
                                                        onClick={() => {
                                                            copyToClipboard(r.record_id);
                                                            success('ID du dossier copié');
                                                        }}
                                                    >
                                                        ID <i className={'pi pi-copy'} />
                                                    </div>
                                                    <Tag
                                                        value={
                                                            r.submitted
                                                                ? 'Soumis le ' +
                                                                  (r.submitted_at
                                                                      ? DateTime.fromISO(r.submitted_at).toFormat(
                                                                            "dd/MM/yy 'à' HH:mm:ss"
                                                                        )
                                                                      : '-')
                                                                : 'Non soumis'
                                                        }
                                                    />
                                                </div>
                                                <div className="he-paragraph--small mt-1">
                                                    Temps passé :{' '}
                                                    {props.timeReport?.modules.find(
                                                        (mInner) => mInner.module_id === m.module_id
                                                    )?.total
                                                        ? Duration.fromMillis(
                                                              props.timeReport?.modules
                                                                  .find((mInner) => mInner.module_id === m.module_id)
                                                                  ?.records.find((rt) => rt.record_id === r.record_id)
                                                                  ?.total ?? 0
                                                          ).toFormat('hh:mm:ss')
                                                        : '--:--:--'}
                                                </div>
                                                <div className="flex flex-column">
                                                    {
                                                        m.activities.map((a, ai) => {
                                                            const checked = !!r.answers.find(ra => ra.activity_id === a.activity_id)
                                                            return (
                                                                <div
                                                                    className="he-paragraph--small flex gap-1 align-items-center mt-1"
                                                                    key={a.activity_id+r.record_id}
                                                                >
                                                                    {checked && (
                                                                        <i className="pi pi-check-circle secondary-75" />
                                                                    )}
                                                                    {!checked && (
                                                                        <Checkbox
                                                                            size={12}
                                                                            checked={checked}
                                                                            onChange={() => {
                                                                                const updatedAnswers = [
                                                                                    ...r.answers,
                                                                                    {
                                                                                        activity_id: a.activity_id,
                                                                                        answer: 0,
                                                                                    },
                                                                                ];
                                                                                updateDoc(
                                                                                    doc(
                                                                                        firestore.db,
                                                                                        'users',
                                                                                        props.participant_id,
                                                                                        'records',
                                                                                        r.record_id
                                                                                    ),
                                                                                    { answers: updatedAnswers }
                                                                                )
                                                                                    .then(() => {
                                                                                        reloadProgress();
                                                                                        success('Activité ajoutée');
                                                                                    })
                                                                                    .catch((e) => {
                                                                                        console.error(e);
                                                                                        error(
                                                                                            "Erreur lors de l'ajout de l'activité"
                                                                                        );
                                                                                    });
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {a.title}
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                </div>

                                            </div>
                                        );
                                    })}
                            </div>
                        )
                    );
				})
			}
		</div>
    )
}
