import { ReadOnlyEditor } from "@Components/ReadOnlyEditor";
import { Activity, FreeTextActivity as FreeTextActivityModel } from "@Types/Activity";
import { Resource } from "@Types/Resource";
import { getUrlForResource } from "@Utils/resource.utils";
import { Galleria } from "primereact/galleria";
import { Image } from 'primereact/image';
import { classNames } from "primereact/utils";
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import ReactPlayer from "react-player";
import { v4 } from "uuid";
import { DisplayAnswer } from './FreeTextActivity/DisplayAnswer';

interface Props {
    activity: Activity & FreeTextActivityModel;
}
export const FreeTextActivity: React.FC<Props> = (props) => {

    const [images, setImages] = useState<(null | Resource | undefined)[]>([]);
    const [videos, setVideos] = useState<Array<null | Resource | undefined>>([]);

    const itemTemplate = (item: Resource) => {
        return (
            <Image
                preview
                src={item?.resource_url}
                alt={item?.resource_name}
                width="auto"
                height={'200px'}
                className="pb-5"
            />
        );
    };

    const thumbnailTemplate = (item: Resource) => {
        return <img src={item.resource_url} alt={item.resource_name} style={{ width: 'auto', height: '70px' }} />;
    };
    useEffect(() => {
        Promise.all(
            props.activity.cover_image_url.map(async (i?: string | null) => {
                if (i) {
                    return getUrlForResource(i);
                }
                return null;
            })
        ).then((imgs) => {
            setImages(imgs.filter((e) => !!e));
        });
        Promise.all(props.activity.cover_video_url.map(async (i?: string | null) => {
            if (i) {
                return getUrlForResource(i);
            }
            return null;
        })).then(vids => {
            setVideos(vids.filter(Boolean));
        })
    }, [props.activity]);

    const responsiveGalleriaOptions = [
        {
            breakpoint: '768px',
            numVisible: 2,
        },
        {
            breakpoint: '450px',
            numVisible: 1,
        },
    ];
    const resources = useMemo(() => {
        return videos.map(v => {
            if (v != null) {
                return <ReactPlayer
                    progressInterval={30_000}
                    config={{
                        file: {
                            attributes: {
                                controlsList: "nodownload"
                            }
                        }
                    }}
                    url={v.resource_url}
                    height={"150px"}
                    width={"auto"}
                    controls
                />
            }
            return null;
        })
    }, [videos])

    return (
        <div className="w-full h-full">
            <div className={classNames('he-header--h1', 'gray-900')}>
                {props.activity.title}
            </div>
            <div className={classNames('quill-custom', 'mt-4')}>
                <ReadOnlyEditor content={props.activity.question || ''} />
            </div>
            {images.length > 0 && (
                <div className="bg-gray-600 pt-5 mt-3">
                    <Galleria
                        circular
                        responsiveOptions={responsiveGalleriaOptions}
                        value={images}
                        numVisible={5}
                        item={itemTemplate}
                        style={{ height: '100%' }}
                        thumbnail={thumbnailTemplate}
                    />
                </div>
            )}
            {
                resources.length > 0 && <div>
                    <div className="he-paragraph--medium gray-900 my-4">Ressources vidéos</div>
                    <div className="flex gap-4 flex-wrap">
                        {resources}
                    </div>
                </div>
            }
            <div className="mt-5">
                <DisplayAnswer activity={props.activity} answer={{
                    activity_id: props.activity.activity_id ?? v4(),
                    done: true,
                    started_at: new Date().toISOString(),
                    answer: "This is a test answer",
                }}/>
            </div>
        </div>
    );

}
